.player {
  height: 600px;
  width: 600px;
}

@media (max-width: 600px) {
  .player {
    height: 100vh;
    width: 300px;
  }
}

.chart-dropdown-select {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #313144;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  float: right;
  box-sizing: border-box;
  outline: none;
}

/* Dashboard Content Top */

/* First Part */
.dash-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: auto;
}
.dash-top::-webkit-scrollbar {
  display: none;
}
.dash-top-card {
  display: flex;
  flex-direction: row;
  height: 150px;
  width: 345px;
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid #167a93;
  border-radius: 10px;
}
.margin-left {
  margin-left: 25px;
}

.dash-top-card-details-left {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
}
.dash-top-card-details-right {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 20px;
}
.dash-top-card-details-left-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.dash-top-card-details-left-price {
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
}
.dash-top-card-details-left-desc {
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-decoration-line: underline;
  color: #2e5dd8;
  cursor: pointer;
}
.dash-top-card-details-right-desc {
  margin-top: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #167a93;
}
.dash-top-card-details-right-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  margin-left: 25px;
  height: 58px;
  width: 58px;
  border: 2.5px solid #167a93;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.05),
    0px 16px 72px -4px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
}
.dash-top-card-details-right-btn {
  background: #167a93;
  border: none;
  height: 50px;
  width: 50px;
}

/* Line chart */
.line-chart-main {
  margin-top: 20px;
  margin-bottom: 20px;
}

.line-div {
  display: flex;
  flex-direction: row;
}

/* second part */
.dash-top-sec-part {
  height: auto;
  width: auto;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.dash-top-sec-part-first-row-dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 30px;
  margin-top: -35px;
  margin-right: 20px;
}

.dash-top-sec-part-first-row {
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
}
.dash-top-sec-part-first-row-revenue {
  font-family: "Spartan", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #333333;
}
.dash-top-sec-part-second-row {
  margin-top: 10px;
}
.dash-top-sec-part-third-row {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.dash-top-sec-part-third-row-revenue-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #9c9c9c;
}
.dash-top-sec-part-third-row-revenue {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #333333;
}
.dash-top-sec-part-third-row-revenue-percentage {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #167a93;
}

/* dashboardContentMiddle */
.dash-middle {
  /* overflow-x: scroll; */
}
.dash-middle-first {
  display: flex;
}
.dash-middle-first-left-chart {
  height: auto;
  width: 50%;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  overflow-x: scroll;
}
.dash-middle-second {
  height: auto;
  width: auto;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

/* donut Chart Css */
.donut-main-div {
  display: flex;
  flex-direction: row;
}
.donut-div {
  margin-top: 3rem;
  margin-left: 30px;
  margin-right: 10px;
}
.donut-product-details-div {
  margin-top: 10px;
}
.donut-product-details {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-left: 10px;
}
.donut-list-card {
  background: #ffffff;
  box-shadow: 0px 10px 15px -3px rgba(17, 17, 17, 0.1),
    0px 4px 6px -2px rgba(17, 17, 17, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 334px;
  width: 302px;
  margin-top: 1rem;
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 20px;
}
.donut-list-scroll {
  overflow-x: scroll;
}
.donut-list {
  display: flex;
  flex-direction: row;
  /* width: 200px; */
}
.donut-list-card-product {
  margin-top: 10px;
  margin-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.donut-list-card-quantity {
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  padding-right: 30px;
}
.donut-list-hr {
  height: 1px;
  border: 0;
  background: lightgray;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: 260px;
}
.donut-list-card-product-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}
.donut-list-card-quantity-detail {
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  padding-right: 30px;
}
.dash-middle-first-right-chart {
  height: auto;
  width: 50%;
  margin-top: 20px;
  margin-left: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  overflow-x: scroll;
}
.barchart {
  margin-top: 2rem;
  margin-left: 5px;
}
.bar {
  background-color: #61dafb;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.donut-list-color-dot {
  height: 10px;
  width: 10px;
  border: 1px solid transparent;
  border-radius: 10px !important;
}

/* Tooltip CSS */
.custom-tooltip {
  position: relative;
  border-bottom: 1px dashed #000;
}

.custom-tooltip:before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 15px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  display: none;
}

.custom-tooltip:hover:before {
  display: block;
}

/* Dashboard content Bottam */
.dash-bottam {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  margin-top: 20px;
}

@media (max-width: 680px) {
  .dash-middle-first {
    display: flex;
    flex-direction: column;
  }
  .dash-middle-first-left-chart {
    height: auto;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #e5e5e5;
    border-radius: 10px;
  }
}

@media (min-width: 1700px) {
  .dash-middle-first {
    display: flex;
    justify-content: center;
  }
  .dash-middle-first-right-chart {
    margin-left: 20px;
  }
  .dash-top-card-margin {
    display: flex;
    flex-direction: row;
    height: 150px;
    width: 360px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    margin-left: 25px;
  }
}
@media (min-width: 1800px) {
  .dash-top {
    display: flex;
    justify-content: center;
  }
}
