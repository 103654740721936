/* Add Size Popup Details */
.addSize-first {
  display: flex;
  flex-direction: column;
}
.addSize-title {
  color: #167a93;
  font-weight: 500;
  font-size: 20px;
}
.addSize-desc {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
}
.addSize-second {
  width: auto;
  height: 180px;
  overflow-x: hidden;
}
.addSize-second-title {
  font-weight: 500;
  font-size: 14px;
  margin-left: 2rem;
}
.addSize-second-hr hr {
  height: 1px;
  border: 0;
  background: black;
  margin-left: 2rem;
  margin-right: 2rem;
  width: auto;
}
.addSize-second-firstRow {
  display: flex;
  flex-direction: row;
}
.addSize-second-right-btn {
  float: right;
  margin-right: 7rem;
  margin-top: 20px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.addSize-second-right-btn-two {
  float: right;
  margin-top: 20px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.addSize-second-right-btn:focus {
  outline: none;
}
.addSize-second-right-btn-two:focus {
  outline: none;
}
.addSize-second-bottam-btn {
  margin-left: 2rem;
}
.addSize-second-bottam-btn-sub {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #167a93;
  border: #167a93;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  transition: box-shadow 0.33s ease-in-out;
}
.addSize-second-bottam-btn-sub:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.addSize-second-bottam-btn-can {
  margin-left: 10px;
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  color: black;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  border: 1px #000000 solid;
}
.addSize-second-bottam-btn-can:focus {
  outline: none;
}

/* Variant Middle Table */
.var-content-middle-size,
.var-content-middle-active {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;

  font-size: 15px;
}
.var-content-middle-measure,
.var-content-middle-action {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;
  font-size: 15px;
}
.var-content-middle-size-data,
.var-content-middle-active-data {
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.var-content-middle-measure-data,
.var-content-middle-action-data {
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 15px;
  min-width: 8rem;
}
.vat-content-middle-action-data-div-delete {
  padding-left: 10px;
}
.var-content-middle-table {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2rem;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 970px) {
  .addSize-second-firstRow {
    display: flex;
    flex-direction: column;
  }
  .addSize-second-bottam-btn {
    margin-top: 20px;
  }
}
