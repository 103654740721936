.showBanner {
  margin-top: 8vh;
}
.sort-icon {
  float: right;
  height: 15px;
  width: 15px;
}
.content-middle-title-tr-sort-name {
  width: 8rem;
}
.content {
  margin-right: 0;
  padding-right: 0;
  background: #f2f2f2;
  display: flex;
  padding-left: 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background: #2f323a;
  width: auto;
  height: 100vh;
}
.sidebar-title {
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding-top: 20px;
}
.sidebar-logo {
  height: 40px;
  width: 40px;
  background-color: #fff;
  margin-right: 10px;
  border-radius: 5px;
}
.sidebar-logo-name {
  margin-right: 20px;
}
.sidebar-tab {
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.close-icon {
  height: 0.5px;
  width: 0.5px;
}
.sb-icon {
  height: 20px;
  width: 12px;
}
.side-bar-navigate {
  color: #fff;
  display: block;
  width: 100%;
  line-height: 60px;
  text-decoration: none;
  padding-left: 40px;
  box-sizing: border-box;
  transition: 0.5s;
  font-family: "Poppins";
  font-style: normal;
  cursor: pointer;
}
.sort-icon-main {
  display: flex;
  justify-content: end;
}

.side-bar-navigate:hover,
.active {
  background: rgba(62, 73, 88, 1);
  border-radius: 10px;
  margin-top: 10px;
}

.sidebar a img {
  height: 22px;
  width: 22px;
}
.sb-product {
  margin-left: -10px;
}
.sb-text {
  text-align: center;
  font-size: 16px;
}
.sb-content {
  /* overflow-y: scroll; */
  /* overflow: hidden; */
  height: 100vh;
}

/* Mobile view */

/* Content-Header */
.content-header-menu {
  display: flex;
  margin-left: 20px;
  visibility: hidden;
}
.content-header-user-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-header-user-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: rgba(22, 122, 147, 0.41);
  border-radius: 50px;
  cursor: pointer;
}
.content-header-user {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  font-size: 20px;
}
.content-header-user-name {
  font-weight: 500;
  font-size: 20px;
  margin-left: 10px;
}
.content-header-button {
  background: #fff;
  outline: none;
  border-width: 0;
}
.content-header-button:focus {
  outline: none;
}
.content-header-downArrow {
  margin-left: 10px;
  height: 18px;
  width: 18px;
  border: none;
  border-color: transparent;
}
.content-header-bell-icon {
  margin-right: 30px;
  cursor: pointer;
}
.notification-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 20rem;
  list-style-type: none;
  margin: 25px 0;
  margin-right: 20px;
  align-items: center;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 7px;
  margin-left: -24.5rem;
  border-color: transparent;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  list-style-type: none;
  margin: 25px 0;
  margin-right: 10px;
  padding: 0;
  border: 1px solid #fff;
  border-radius: 7px;
  margin-left: -9.2rem;
  border-color: transparent;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.menu-btn {
  border: lightgray;
}
.menu-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
}
.menu-hr {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid gray;
}
.menu > li {
  margin: 0;
  background-color: white;
  border-color: transparent;
  font-family: "Karla";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
}

.menu > li:hover {
  background-color: lightgray;
  border-color: transparent;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;
  border-color: transparent;
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}
.menu-profile-logo {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}
.menu-item {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  width: 100%;
}
.menu-btn-title {
  padding-left: 20px;
}
.profile {
  display: flex;
  flex-direction: column;
}
.profile-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  align-self: center;
  color: #656565;
}

.home-section nav {
  justify-content: space-between;
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
  background: #ffffff;
  box-shadow: 0px 10px 15px -3px rgba(17, 17, 17, 0.1),
    0px 4px 6px -2px rgba(17, 17, 17, 0.05);
  border-radius: 12px;
}
.sidebar.active ~ .home-section nav {
  left: 60px;
  width: calc(100% - 60px);
}
.home-section nav .sidebar-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}
nav .sidebar-button i {
  font-size: 35px;
  margin-right: 10px;
}
.home-section nav .search-box {
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
  border: #ffffff;
  padding-left: 20px;
}
nav .search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 20px;
  font-size: 18px;
  padding: 0 15px;
}
nav .search-box .bx-search-alt-2 {
  position: absolute;
  height: 40px;
  width: 40px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  font-size: 22px;
  transition: all 0.4 ease;
}
.home-section nav .profile-details {
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 50px;
}
.home-section .home-content {
  position: relative;
  padding-top: 104px;
}

.content-header-dropdown {
}
/* Content Navigation */

.content-nav {
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.content-nav-home {
  padding-left: 10px;
  cursor: pointer;
}
.bx-chevron-right {
  padding-left: 10px;
}
/* Content */
.content-scroll {
  overflow-y: auto;
  height: calc(100vh - 114px);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.content-scroll::-webkit-scrollbar {
  display: none;
}

.content-card {
  display: flex;
  margin-top: 0.5rem;
  height: auto;
  width: auto;
  background-color: #ffffff;
  align-items: initial;
  margin-bottom: 2rem;
  padding-left: 20px;
  padding-right: 20px;
}
.content-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.content-top-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
}
.content-top-left-title {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  color: #167a93;
}
.content-top-left-desc {
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
}
.content-top-left-dropdown {
  margin-top: 20px;
  color: #3e4958;
}
.content-top-right {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.content-top-right-btn {
  margin-top: 10px;
  float: right;
  width: auto;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: box-shadow 0.33s ease-in-out;
}
.content-top-right-btn:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.content-top-right-btn-icon {
  height: 14px;
  width: 10px;
}
.content-top-right-searchbar {
  display: flex;
  margin-top: 2rem;
}
.content-searchBar {
  display: flex;
  align-items: center;
  width: 230px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e8ec;
  border-radius: 5px;
  box-shadow: 0px 10px 60px rgba(79, 79, 79, 0.2);
}
.drop-search {
  margin-left: 15px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  background: #ffffff;
}
.drop-search-container {
  flex-grow: 0;
  border-radius: 5px 0px 0px 5px;
  background: #ffffff;
  position: relative;
  border: 1px solid #e6e8ec;
  width: 81px;
  height: 40px;
  min-height: 40px;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.content-searchBar-input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #ffffff;
  border: none;
  font-size: 16px;
  margin-right: 10px;
  margin-left: 15px;
}
.content-searchBar-search {
  height: 30px;
  width: 30px;
  padding-left: 10px;
}
.content-top-right-btn-close {
  background-color: transparent;
  border: none;
  float: right;
  margin-top: 10px;
  border-radius: 5px;
  margin-right: 1rem;
  height: 45px;
  padding: 10px;
}
.content-top-right-btn-close:focus {
  outline: none;
}
.content-top-right-btn-close-icon {
  height: 15px;
  width: 15px;
}

.addCustomer-first {
  display: flex;
  flex-direction: column;
}
.addCustomer-title {
  color: #167a93;
  font-weight: 500;
  font-size: 20px;
}
.addCustomer-desc {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
}
.addCustomer-second {
  width: auto;
  height: 430px;
  overflow-x: hidden;
  overflow-y: auto;
}
.addCustomer-second-title {
  font-weight: 500;
  font-size: 14px;
  margin-left: 18px;
}
.addCustomer-second-hr hr {
  height: 1px;
  border: 0;
  background: black;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
}
.addCustomer-second-firstRow {
  display: flex;
  flex-direction: row;
}
.addCustomer-second-right-btn {
  float: right;
  margin-right: 1rem;
  margin-top: 20px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.addCustomer-second-right-btn-two {
  float: right;
  margin-top: 20px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.addCustomer-second-right-btn:focus {
  outline: none;
}
.addCustomer-second-right-btn-two:focus {
  outline: none;
}
.addCustomer-second-bottam-btn {
  margin-left: 2rem;
}
.addCustomer-second-bottam-btn-sub {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #167a93;
  border: #167a93;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  transition: box-shadow 0.33s ease-in-out;
}
.addCustomer-second-bottam-btn-sub:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.addCustomer-second-bottam-btn-can {
  margin-left: 10px;
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  color: black;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  border: 1px #000000 solid;
}
.addCustomer-second-bottam-btn-can:focus {
  outline: none;
}
.content-middle {
  margin-top: 2rem;
  margin-left: 10px;
  margin-right: 10px;
  overflow-x: scroll;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.content-middle-main {
  overflow: hidden;
}
.content-middle-title-tr {
  background: rgba(247, 247, 247, 1);
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 600;
  border-bottom: white 2px solid;
  font-size: 13px;
}
tr:nth-child(odd) {
  background: #ffffff;
}
tr:nth-child(even) {
  background: rgba(247, 247, 247, 1);
}
.content-middle-name,
.content-middle-mobile,
.content-middle-active,
.content-middle-city,
.content-middle-refName,
.content-middle-action {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 400;
  font-size: 15px;
}
.content-middle-email,
.content-middle-whats,
.content-middle-date,
.content-middle-address,
.content-middle-refAddress {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 400;
  font-size: 15px;
}
.content-middle-name-data,
.content-middle-mobile-data,
.content-middle-active-data,
.content-middle-city-data,
.content-middle-refName-data,
.content-middle-action-data {
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.content-middle-email-data,
.content-middle-whats-data,
.content-middle-date-data,
.content-middle-address-data,
.content-middle-refAddress-data {
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 15px;
  min-width: 8rem;
}
.content-middle-email-data,
.content-middle-date-data {
  text-transform: lowercase;
}
.content-middle-date-data {
  min-width: 8rem;
}
.content-middle-action-data-div-delete {
  padding-left: 10px;
}
.del-pop {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.del-pop-icon {
  height: 50px;
  width: 50px;
}
.del-pop-text-div {
  display: flex;
  justify-content: center;
}
.del-pop-text {
  font-size: 22px;
  margin-top: 20px;
  text-align: center;
}
.del-pop-btn-yes {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #f70d1a;
  color: #ffffff;
  border: none;
}
.del-pop-btn-no {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  color: #000000;
  border: none;
}
.add-doc-main-div {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.add-doc {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 320px;
  border: 1px gray solid;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.doc-img {
  height: 60px;
  width: 60px;
}
.doc-chose {
  height: 60px;
  width: 60px;
}
.doc-title {
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
}
.doc-sub {
  margin-top: 10px;
  font-weight: 500;
  font-size: 10px;
}
.selected-img {
  height: 150px;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px gray solid;
  border-radius: 10px;
  background-size: contain;
}
.doc-pdf-i {
  height: 50px;
  width: 50px;
}
.sel-name {
  margin-top: 15px;
  font-size: 15px;
}
.sort-main {
  display: flex;
}

.payment-limit-dropdown {
  display: flex;
  align-items: center;
  margin-left: 181px;
  margin-top: -55px;
  margin-right: 20px;
}
.payment-limit-dropdown-select {
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7.5px;
  padding-bottom: 7px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #313144;
  border: 1px solid #000000;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  float: right;
  box-sizing: border-box;
  outline: none;
}
.l-radio {
  margin-left: 20px;
}
.payment-limit-div {
  display: flex;
}
.payment-limit-text {
  font-size: 16px;
  padding-left: 10px;
}
/* Responsive CSS */

.sidebar-main {
  display: flex;
  position: absolute;
  width: 20rem;
  z-index: 100;
  left: -100vw;
  /* transition-property: left, top;
    transition-duration: 3s, 5s, 3s, 5s; */
  transition: 1s;
}
.sidebar-close {
  /* position: absolute; */
  animation: close;
  animation-duration: 200ms;
  animation-timing-function: linear;
  animation-direction: normal;
}
@keyframes close {
  0% {
    left: 0vw;
    top: 0px;
  }
  100% {
    left: -100vw;
    top: 0px;
  }
}
.sidebar {
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.open-sidebar {
  left: 0;
  position: absolute;
  animation: MyDiv;
  animation-duration: 200ms;
  animation-timing-function: linear;
  animation-direction: normal;
}
@keyframes MyDiv {
  0% {
    left: -100vw;
    top: 0px;
  }
  100% {
    left: 0vw;
    top: 0px;
  }
}
.close-icon {
  color: #fff;
  height: 25px;
  width: 25px;
  margin-top: 20px;
  display: flex;
  align-self: flex-end;
  margin-right: 20px;
}
.sidebar-tab {
  padding-top: 35px;
  width: 100%;
  /* text-align: center; */
}
.content-header-menu {
  visibility: visible;
}
@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: none;
    padding: 0.25em;
  }
}
@media (max-width: 520px) {
  .content-top {
    display: flex;
    flex-direction: column;
  }
  .content-top-right-btn {
    align-self: center;
    margin-top: 1rem;
  }
  .content-top-right-searchbar {
    display: flex;
    margin-left: -15px;
  }
  .deleteCustomer-box {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
  .del-pop-text {
    font-size: 18px;
  }
}
@media (max-width: 970px) {
  .addCustomer-second-firstRow {
    display: flex;
    flex-direction: column;
  }
  .addCustomer-second-bottam-btn {
    margin-top: 20px;
  }
}

.search-dropdown {
  background-color: white;
}
