.ex-content-top-title {
  margin-top: 20px;
  font-weight: 500;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #167a93;
  margin-left: 18px;
}
.ex-content-top-input {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

/* Expenses Middle */
.ex-content-middle {
  margin-bottom: 20px;
}

.expense-border {
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

@media (max-width: 650px) {
  .ex-content-top-input {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
}
@media (max-width: 1475px) {
  .ex-content-top {
    overflow-x: scroll;
  }
  .expense-border {
    width: 110%;
  }
}
