.push-notification-title {
  font-size: 17px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.push-notification-text {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.btn-primary {
  height: 48px;
  width: 240px;
  border-radius: 8px;
  border: 1px solid #2a63b0;
  background: #1a75ed;
  cursor: pointer;
  font-size: 15px;
  color: #ffffff;
}

.btn-primary:active {
  border: 1px solid #1f4373;
}

.notification-banner {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 16px;
  background: #3e5fcd;
  font-size: 16px;
  color: #ffffff;
}

.notification-banner-link {
  margin-left: 4px;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}
