/* Payment Content Top */
.payment-main {
  display: flex;
}
.payment-top-left-first {
  display: flex;
  flex-direction: column;
}
.payment-top-left-title {
  font-weight: 500;
  font-size: 20px;
  color: #167a93;
}
.payment-top-left-desc {
  margin-top: 5px;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}
.payment-top-left-second {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-left: -15px;
}
.pay-content-top-date-label {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin-top: 10px;
}
.payment-inputText-date-container {
  flex-grow: 0;
  background: #167a93;
  border-radius: 5px;
  position: relative;
  width: 143px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 10px;
}
.payment-inputText-input-date {
  flex-grow: 0;
  border-radius: 5px;
  background: #167a93;
  color: #fff;
  border: none;
  font-weight: 500;
  padding: 10px;
  margin-left: -10px;
  position: relative;
  width: 110px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  border-right: 1px solid #fff;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.payment-inputText-date-container :focus-visible {
  outline: none;
}
.payment-inputText-input-date :focus-visible {
  outline: none;
}
.pay-content-top-date {
  width: 143px;
  height: 41px;
  background: #167a93;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  color-scheme: dark;
}
.payment-date-row {
  display: flex;
  flex-direction: column;
}
.payment-select-input {
  width: 373px;
}
.payment-pdf-btn {
  float: right;
  margin-top: 20px;
  background: #167a93;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  border: none;
  padding: 13px 12px;
  border-radius: 5px;
}

/* Payment Table */
.payment-table {
  border-collapse: collapse;
  margin-top: 1rem;
}
.payment-tr {
  border-bottom: 1px solid #000000;
  line-height: 2.8em;
}
.payment-td-one {
  padding: 10px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  font-family: "Montserrat";
  background-color: #ffffff;
}
.payment-td-one-total {
  padding: 10px;
  width: 70%;
  font-weight: 600;
  font-size: 18px;
  font-family: "Montserrat";
  background-color: #ffffff;
}
.payment-td-two {
  width: 30%;
  text-align: right;
  font-weight: 500;
  font-size: 20px;
  padding-right: 20px;
  font-family: "Montserrat";
  background-color: #ffffff;
}
.payment-td-two-total {
  font-weight: 600;
  font-size: 22px;
  width: 30%;
  text-align: center;
  font-family: "Montserrat";
  background-color: #ffffff;
}
.payment-td-two-total-input {
  width: 120px;
  background: #c7e9b0;
  color: #000;
  font-weight: bold;
  font-family: "Inter";
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  outline: none;
}
.payment-td-two-total-input :focus-visible {
  outline: none;
}
.payment-td-two-loss-input {
  width: 120px;
  background: #f7a4a4;
  color: #000;
  border: none;
  font-weight: bold;
  font-family: "Inter";
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  outline: none;
}
.payment-td-two-loss-input :focus-visible {
  outline: none;
}
.payment-td-two-depo-input {
  width: 120px;
  background: #ffffe0;
  color: #000;
  border: none;
  font-weight: bold;
  font-family: "Inter";
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  outline: none;
}
.payment-td-two-loss-input :focus-visible {
  outline: none;
}
.payment-td-two-r-depo-input {
  width: 120px;
  background: #a0e7e5;
  color: #000;
  border: none;
  font-weight: bold;
  font-family: "Inter";
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  outline: none;
}
.payment-total-border {
  width: 188px;
  margin-top: 5px;
  border-bottom: 2px solid #000000;
}
.payment-tr-loss {
  border-bottom: 1px solid #000000;
  line-height: 2.8em;
  background-color: #e96479;
}
.payment-card-main {
  margin-top: 9rem;
  display: flex;
  flex-direction: column;
  float: left;
}
.payment-card-one {
  margin-top: 20px;
  width: 220px;
  height: 123px;
  align-items: normal;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 15px;
}
.payment-card-title-one {
  font-weight: 600;
  font-size: 20px;
  color: #167a93;
  /* color: #ffffff; */
  text-align: left;
}
.payment-card-desc-one {
  font-weight: 600;
  font-size: 24px;
  /* color: #167a93; */
  color: #000;
}
.payment-card-two {
  margin-top: 20px;
  width: 220px;
  height: 123px;
  align-items: normal;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 15px;
}
.payment-card-title-two {
  font-weight: 600;
  font-size: 20px;
  color: #167a93;
  text-align: left;
}
.payment-card-desc-two {
  font-weight: 600;
  font-size: 24px;
  /* color: #167a93; */
  color: #000;
}
.payment-card-three {
  margin-top: 20px;
  width: 220px;
  height: 123px;
  align-items: normal;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 15px;
}
.payment-card-title-three {
  font-weight: 600;
  font-size: 20px;
  color: #167a93;
  text-align: left;
}
.payment-card-desc-three {
  font-weight: 600;
  font-size: 24px;
  color: #000;
}
.payment-card-four {
  margin-top: 20px;
  width: 220px;
  height: 123px;
  align-items: normal;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 15px;
}
.payment-card-title-four {
  font-weight: 600;
  font-size: 20px;
  color: #167a93;
  text-align: left;
}
.payment-card-desc-four {
  font-weight: 600;
  font-size: 24px;
  color: #000;
}
.payment-card-icon {
  align-self: flex-end;
  height: 30px;
  width: 30px;
}
.payment-border {
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.payment-btn {
  margin-top: 20px;
  margin-left: 25px;
  margin-bottom: 20px;
}
.payment-btn-sub {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #167a93;
  border: #167a93;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  transition: box-shadow 0.33s ease-in-out;
}
.payment-btn-sub:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.payment-btn-can {
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #ffffff;
  color: black;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  border: 1px #000000 solid;
}
.payment-btn-can:focus {
  outline: none;
}

/* PAYMENT MIDDLE */
.payment-middle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.payment-content-middle-main {
  margin-top: 20px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .payment-main {
    display: flex;
    flex-direction: column;
  }
  .payment-card-main {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }
  .payment-card-main-one {
    display: flex;
    flex-direction: column;
  }
  .payment-card-one {
    margin-left: 60px;
  }
  .payment-card-two {
    margin-left: 60px;
  }
  .payment-card-three {
    margin-left: 60px;
  }
  .payment-card-four {
    margin-left: 60px;
  }
}

@media (max-width: 700px) {
  .payment-card-one {
    margin-left: 30px;
  }
  .payment-card-two {
    margin-left: 30px;
  }
  .payment-card-three {
    margin-left: 30px;
  }
  .payment-card-four {
    margin-left: 30px;
  }
}
@media (max-width: 600px) {
  .payment-card-one {
    margin-left: 10px;
  }
  .payment-card-two {
    margin-left: 10px;
  }
  .payment-card-three {
    margin-left: 10px;
  }
  .payment-card-four {
    margin-left: 10px;
  }
  .payment-top-left-second {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .payment-date-row {
    margin-left: -70px;
  }
}
@media (max-width: 550px) {
  .payment-main {
    display: flex;
    flex-direction: column;
  }
  .payment-card-main {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .payment-card-main-one {
    display: flex;
    flex-direction: column;
  }
  .payment-inputText-date-container {
    margin-left: -20px;
  }
}
