.cat-content-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.cat-content-top-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
}
.cat-content-top-left-title {
  display: flex;
  flex: 0.5;
  font-weight: 500;
  font-size: 20px;
  color: rgba(22, 122, 147, 1);
}
.cat-content-top-left-desc {
  font-weight: 400;
  font-size: 15px;
}
.cat-content-top-left-dropdown {
  margin-top: 20px;
  color: #3e4958;
}
.cat-content-top-right {
  display: flex;
  flex-direction: column;
}
.cat-content-top-right-btn {
  margin-top: 10px;
  align-self: flex-end;
  width: 150px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 14px;
  padding: 8px 8px;
}
.cat-content-top-right-btn:focus {
  outline: none;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
}
.cat-content-top-right-btn-icon {
  height: 14px;
  width: 10px;
}
.cat-content-searchBar {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  border: 1px solid #000000;
  width: auto;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 10px 60px rgba(79, 79, 79, 0.2);
}
.cat-content-searchBar-input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #ffffff;
  border: none;
  font-size: 18px;
  border-right: 1px solid #000000;
  margin-right: 10px;
  margin-left: 15px;
}
.cat-content-searchBar-search {
  height: 30px;
  width: 30px;
  padding-right: 10px;
}

/* Cat Content Top Popup */
.cat-first {
  display: flex;
  flex-direction: column;
}
.cat-title {
  color: #167a93;
  font-weight: 500;
  font-size: 20px;
}
.cat-desc {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
}
.cat-second {
  width: auto;
  height: 180px;
  overflow-x: hidden;
}
.cat-second-title {
  font-weight: 500;
  font-size: 14px;
  margin-left: 2rem;
}
.cat-second-hr hr {
  height: 1px;
  border: 0;
  background: black;
  margin-left: 2rem;
  width: 897px;
}
.cat-second-firstRow {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
.cat-second-right-btn {
  float: right;
  margin-right: 7rem;
  margin-top: 20px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.cat-second-right-btn-two {
  float: right;
  margin-top: 20px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.cat-second-right-btn:focus {
  outline: none;
}
.cat-second-right-btn-two:focus {
  outline: none;
}
.cat-second-bottam-btn {
  margin-left: 2rem;
}
.cat-second-bottam-btn-sub {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #167a93;
  border: #167a93;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  transition: box-shadow 0.33s ease-in-out;
}
.cat-second-bottam-btn-sub:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.cat-second-bottam-btn-can {
  margin-left: 10px;
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  color: black;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  border: 1px #000000 solid;
}
.cat-second-bottam-btn-can:focus {
  outline: none;
}

/* Cat Content Middle */
.cat-content-middle {
  margin-top: 2rem;
  margin-left: 10px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.cat-content-middle-main {
  overflow: hidden;
}
.cat-content-middle-name,
.cat-content-middle-action {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;
  font-size: 13px;
}
.cat-content-middle-active {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;
}
.cat-content-middle-name-data,
.cat-content-middle-action-data {
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.cat-content-middle-active-data {
  border-bottom: white 1px solid;
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 15px;
  min-width: 8rem;
}

.cat-content-middle-action-data-div-delete {
  padding-left: 10px;
}

@media (max-width: 970px) {
  .cat-second-firstRow {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1180px) {
  .cat-content-middle {
    overflow-x: scroll;
  }
}
