/* Add Size Popup Details */
.addProduct-first {
  display: flex;
  flex-direction: column;
}
.addProduct-title {
  color: #167a93;
  font-weight: 500;
  font-size: 20px;
}
.addProduct-desc {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
}
.addProduct-second {
  width: auto;
  height: 280px;
  overflow-x: hidden;
}
.addProduct-second-title {
  font-weight: 500;
  font-size: 14px;
  margin-left: 2rem;
}
.addProduct-second-hr hr {
  height: 1px;
  border: 0;
  background: black;
  margin-left: 2rem;
  width: 897px;
}
.addProduct-second-firstRow {
  display: flex;
  flex-direction: row;
}
.addProduct-second-right-btn {
  float: right;
  margin-right: 7rem;
  margin-top: 20px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.addProduct-second-right-btn-two {
  float: right;
  margin-top: 20px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.addProduct-second-right-btn:focus {
  outline: none;
}
.addProduct-second-right-btn-two:focus {
  outline: none;
}
.addProduct-second-bottam-btn {
  margin-left: 2rem;
}
.addProduct-second-bottam-btn-sub {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #167a93;
  border: #167a93;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  transition: box-shadow 0.33s ease-in-out;
}
.addProduct-second-bottam-btn-sub:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.addProduct-second-bottam-btn-can {
  margin-left: 10px;
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  color: black;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  border: 1px #000000 solid;
}
.addProduct-second-bottam-btn-can:focus {
  outline: none;
}

/* Product Content Middle */
.product-content-middle {
  margin-top: 2rem;
}
.product-content-middle-main {
  border-radius: 15px;
  overflow: hidden;
}
.product-content-middle-category,
.product-content-middle-measure,
.product-content-middle-active {
  background: #d8dce3;
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;
  border-right: white 2px solid;
  border-bottom: white 2px solid;
  font-size: 15px;
}
.product-content-middle-size,
.product-content-middle-quntity,
.product-content-middle-action {
  background: #e4e7ec;
  margin-right: 10px;
  padding: 7px 10px;
  border-right: white 2px solid;
  border-bottom: white 2px solid;
  font-weight: 500;
  font-size: 15px;
}
.product-content-middle-category-data,
.product-content-middle-measure-data,
.product-content-middle-active-data {
  background: #e8e8e8;
  border-bottom: white 2px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  border-right: white 2px solid;
  min-width: 8rem;
}
.product-content-middle-size-data,
.product-content-middle-quntity-data,
.product-content-middle-action-data {
  border-bottom: white 2px solid;
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 15px;
  border-right: white 2px solid;
  background: #d8dce350;
  min-width: 8rem;
}
.product-content-middle-active-data {
  text-align: center;
}
.product-content-middle-action-data {
  text-align: center;
}

@media (max-width: 970px) {
  .addProduct-second-firstRow {
    display: flex;
    flex-direction: column;
  }
  .addProduct-second-bottam-btn {
    margin-top: 20px;
  }
}
