/* Date picker */
.rmdp-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 180px;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: 5px;
  background-image: url("./../../assets/svgs/CalenderIcon.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}
.rmdp-container :focus-visible {
  outline: none;
}
.rmdp-input {
  flex-grow: 0;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
  position: relative;
  width: 130px;
  min-height: 37px;
  display: flex;
  font-size: 16px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
}
.rmdp-input :focus-visible {
  outline: none;
}

.ro-content-middle-no,
.ro-content-middle-r-qty,
.ro-content-middle-t-rent,
.ro-content-middle-r-date,
.ro-content-middle-t-days,
.ro-content-middle-t-e-d-rent,
.ro-content-middle-d-q-charge {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 600;
}
.ro-content-middle-name,
.ro-content-middle-u-rent,
.ro-content-middle-o-date,
.ro-content-middle-e-days,
.ro-content-middle-e-d-rent,
.ro-content-middle-d-qty,
.ro-content-middle-t-amount {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 600;
}
.ro-content-middle-no-data {
  min-width: 0.5rem;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  text-align: center;
}
.ro-content-middle-r-qty-data,
.ro-content-middle-t-rent-data,
.ro-content-middle-r-date-data,
.ro-content-middle-t-days-data,
.ro-content-middle-t-e-d-rent-data,
.ro-content-middle-d-q-charge-data {
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;

  min-width: 8rem;
}

.ro-content-middle-name-data,
.ro-content-middle-u-rent-data,
.ro-content-middle-o-date-data,
.ro-content-middle-e-days-data,
.ro-content-middle-e-d-rent-data,
.ro-content-middle-d-qty-data,
.ro-content-middle-t-amount-data {
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 15px;

  min-width: 8rem;
}
.ro-content-middle-ext-1,
.ro-content-middle-ext-3 {
  text-align: right;
}

.ro-content-middle-ext-1,
.ro-content-middle-ext-2,
.ro-content-middle-ext-3,
.ro-content-middle-ext-4,
.ro-content-middle-ext-5,
.ro-content-middle-ext-6 {
  background: rgba(143, 186, 110, 0.5);
  border-bottom: white 2px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.ro-content-middle-ext-7 {
  background: #43c507;
  border-bottom: white 2px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;

  min-width: 8rem;
}
.ro-content-middle-v-1 {
  text-align: right;
}
.ro-content-middle-v-1,
.ro-content-middle-v-2 {
  background: #e7e2cd;
  border-bottom: white 2px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.ro-content-middle-l-1 {
  text-align: right;
}
.ro-content-middle-l-1,
.ro-content-middle-l-2 {
  background: #e5d1fa;
  border-bottom: white 2px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.ro-content-middle-t-1 {
  text-align: right;
}
.ro-content-middle-t-1,
.ro-content-middle-t-2 {
  background: #d8dce3;
  border-bottom: white 2px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.ro-content-top {
  display: flex;
  flex-direction: row !important;
  margin-top: 10px;
}

/* Invoice CSS */
.invoice-date-name {
  display: flex;
  margin-top: 2rem;
}
.invoice-main {
  border: 1px solid #000;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.invoice-part-first {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.invoice-part-first-text {
  font-weight: bold;
  font-size: 12px;
}
.invoice-part-first-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.invoice-part-first-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
.invoice-part-first-middle-name {
  color: #005900;
  font-size: 30px;
  padding-top: 10px;
  font-weight: 600;
}
.invoice-part-first-middle-logo {
  height: 60px;
  width: 60px;
}
.invoice-part-first-last {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.invoice-text {
  color: #43c507;
}
.invoice-table-tr {
  min-width: 40px;
}
.invoice-part-second {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.invoice-part-second-one {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
}
.invoice-part-second-one-text {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
.invoice-part-second-two {
  display: flex;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
.invoice-part-second-two-1 {
  border-right: 1px solid lightgray;
}
.invoice-part-second-three {
  display: flex;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.invoice-part-second {
  display: flex;
  margin-top: 10px;
}
.ro-in {
  margin-top: 10px;
}
.ro-in-table {
  border: black 1px solid;
}

.ro-in-content-middle-no,
.ro-in-content-middle-r-qty,
.ro-in-content-middle-t-rent,
.ro-in-content-middle-r-date,
.ro-in-content-middle-t-days,
.ro-in-content-middle-t-e-d-rent,
.ro-in-content-middle-d-q-charge {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;
  border: black 1px solid;
}
.ro-in-content-middle-name,
.ro-in-content-middle-u-rent,
.ro-in-content-middle-o-date,
.ro-in-content-middle-e-days,
.ro-in-content-middle-e-d-rent,
.ro-in-content-middle-d-qty,
.ro-in-content-middle-t-amount {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;
  border: black 1px solid;
}
.ro-in-content-middle-no-data {
  min-width: 0.5rem;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  text-align: center;
  border-right: black 1px solid;
}
.ro-in-content-middle-r-qty-data,
.ro-in-content-middle-t-rent-data,
.ro-in-content-middle-r-date-data,
.ro-in-content-middle-t-days-data,
.ro-in-content-middle-t-e-d-rent-data,
.ro-in-content-middle-d-q-charge-data {
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  border-right: black 1px solid;
}

.ro-in-content-middle-name-data,
.ro-in-content-middle-u-rent-data,
.ro-in-content-middle-o-date-data,
.ro-in-content-middle-e-days-data,
.ro-in-content-middle-e-d-rent-data,
.ro-in-content-middle-d-qty-data,
.ro-in-content-middle-t-amount-data {
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 15px;
  border-right: black 1px solid;
}
.ro-in-content-middle-ext-1,
.ro-in-content-middle-ext-3 {
  text-align: right;
}

.ro-in-content-middle-ext-1,
.ro-in-content-middle-ext-2,
.ro-in-content-middle-ext-3,
.ro-in-content-middle-ext-4,
.ro-in-content-middle-ext-5,
.ro-in-content-middle-ext-6 {
  /* background: rgba(143, 186, 110, 0.5); */
  border-top: black 1px solid;
  border-bottom: black 1px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  border-right: black 1px solid;
}
.ro-in-content-middle-ext-7 {
  /* background: #43c507; */
  border-top: black 1px solid;

  border-bottom: black 1px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  border-right: black 1px solid;
}
.ro-in-content-middle-v-1 {
  text-align: right;
}
.ro-in-content-middle-v-1,
.ro-in-content-middle-v-2 {
  /* background: #e7e2cd; */
  border-bottom: black 1px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  border-right: black 1px solid;
}
.ro-in-content-middle-l-1 {
  text-align: right;
}
.ro-in-content-middle-l-1,
.ro-in-content-middle-l-2 {
  /* background: #e5d1fa; */
  border-bottom: black 1px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  border-right: black 1px solid;
}
.ro-in-content-middle-t-1 {
  text-align: right;
}
.ro-in-content-middle-t-1,
.ro-in-content-middle-t-2 {
  /* background: #d8dce3; */
  border-bottom: black 1px solid;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  border-right: black 1px solid;
}
.ro-in-content-middle-t-2 {
  font-weight: bold;
}
.invoice-part-three {
  display: flex;
  margin-bottom: 1rem;
}
.invoice-part-three-1 {
  display: flex;
  justify-content: flex-start;
  margin-top: 3rem;
}
.invoice-part-three-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.invoice-part-three-3 {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}

.in-content-middle-t-1 {
  text-align: right;
}
.in-content-middle-t-1,
.in-content-middle-t-2 {
  background: transparent;
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}

/* @media all {
  .page-break {
    display: none;
  }
} */

@media print {
  .page-break {
    display: block;
    page-break-after: always;
  }
}

/* @page {
  size: auto;
  margin: 10mm;
} */
