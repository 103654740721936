.ac-inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  width: 100%;
  height: 35px;
  outline: none;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Inter";
}
.ac-add-inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  width: 100%;
  height: auto;
  outline: none;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.ac-inputText-input-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  margin-bottom: 10px;
  min-height: 40px;
}
.ac-inputText-input-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 259px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 20px;
}
.ac-inputText-date-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 239px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 20px;
}
.ac-inputText-input-date {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  margin-left: -10px;
  position: relative;
  width: 190px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 20px;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}
.ac-inputText-date-container :focus-visible {
  outline: none;
}
.ac-inputText-input-date :focus-visible {
  outline: none;
}
.ac-add-inputText-input-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 259px;
  height: 51px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}
.ac-inputText-label {
  font-size: 15px;
  padding-left: 5px;
}
.ac-inputText-img {
  height: 20px;
  width: 20px;
}
.ac-inputText-error-container {
  background-color: #ffbbc2;
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ac-inputText-error-container span {
  margin-left: 10px;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
}

.ro-inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  width: 100%;
  height: 35px;
  outline: none;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Inter";
}
.ro-add-inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  width: 100%;
  height: auto;
  outline: none;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.ro-inputText-input-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  margin-bottom: 10px;
  min-height: 40px;
}
.ro-inputText-input-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 165px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 20px;
}
.ro-add-inputText-input-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 165px;
  height: 51px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.ro-inputText-label {
  font-size: 15px;
  /* padding-left: 15px; */
}
.ro-inputText-img {
  height: 20px;
  width: 20px;
}
.ro-inputText-error-container {
  background-color: #ffbbc2;
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ro-inputText-error-container span {
  margin-left: 10px;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
}

.payment-inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  width: 100%;
  height: 35px;
  outline: none;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Inter";
}
.payment-add-inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  width: 100%;
  height: auto;
  outline: none;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.payment-inputText-input-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  margin-bottom: 10px;
  min-height: 40px;
}
.payment-inputText-input-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 167px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.payment-add-inputText-input-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 165px;
  height: 51px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.payment-inputText-label {
  font-size: 15px;
  /* padding-left: 15px; */
}
.payment-inputText-img {
  height: 20px;
  width: 20px;
}
.payment-inputText-error-container {
  background-color: #ffbbc2;
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.payment-inputText-error-container span {
  margin-left: 10px;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
}
