/* Add Customer Popup */
.addCustomer-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.addCustomer-box {
  position: relative;
  width: 920px;
  margin: 0 auto;
  height: 587px;
  max-height: 90vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  padding: 20px 0px 20px 20px;
}
/* Order Summery Popup */
.summery-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.summery-box {
  position: relative;
  width: auto;
  margin: 0 auto;
  height: 687px;
  max-height: 90vh;
  margin-top: calc(100vh - 92vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  padding: 20px 0px 20px 20px;
  margin-left: 10px;
  margin-right: 10px;
}

/* delete popup */
.deleteCustomer-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
.deleteCustomer-box {
  position: relative;
  width: 520px;
  margin: 0 auto;
  height: auto;
  max-height: 90vh;
  margin-top: calc(100vh - 70vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  border-radius: 10px;
}

/* Add Site Popup */
.addSite-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.addSite-box {
  position: relative;
  width: 920px;
  margin: 0 auto;
  height: auto;
  max-height: 90vh;
  margin-top: calc(100vh - 70vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  padding: 20px 0px 20px 20px;
}

/* Add Category popup */
.addCat-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.addCat-box {
  position: relative;
  width: 1083px;
  margin: 0 auto;
  height: 340px;
  max-height: 90vh;
  margin-top: calc(100vh - 70vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  padding: 20px 0px 20px 20px;
}

/* Add Size Popup */
.addSize-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.addSize-box {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  height: 350px;
  max-height: 80vh;
  margin-top: calc(100vh - 70vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  padding: 20px 0px 20px 20px;
}

/* Add Product Popup */
.addProduct-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.addProduct-box {
  position: relative;
  width: 920px;
  margin: 0 auto;
  height: 440px;
  max-height: 90vh;
  margin-top: calc(100vh - 76vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  padding: 20px 0px 20px 20px;
}

/* ToggleLoading Popup */
.toggle-box-main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: transparent;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.toggle-box {
  position: relative;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  border: none;
  overflow: auto;
  padding: 10px 10px 10px 10px;
}

/* Open Profile Popup */
.profile-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.profile-box {
  position: relative;
  width: 500px;
  margin: 0 auto;
  max-height: 100vh;
  margin-top: calc(100vh - 92vh - 30px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  padding: 20px 0px 20px 20px;
  background-color: #f8f8f8;
}

@media (max-width: 500px) {
  .profile-box {
    width: auto;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  .inputText-input-container {
    width: auto;
  }
  .profile-button-cancel {
    width: 100px;
  }
  .profile-button-change {
    width: 100px;
  }
}

@media (max-width: 1088px) {
  /* Add Customer Popup */
  .addCustomer-box {
    position: relative;
    width: 983px;
    margin: 0 auto;
    height: 587px;
    max-height: 90vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    padding: 20px 0px 20px 20px;
  }

  .summery-box {
    position: relative;
    width: 883px;
    margin: 0 auto;
    height: 687px;
    max-height: 90vh;
    margin-top: calc(100vh - 90vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    padding: 20px 0px 20px 20px;
  }
  .addSite-box {
    position: relative;
    width: 883px;
    margin: 0 auto;
    height: 300px;
    max-height: 90vh;
    margin-top: calc(100vh - 70vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    padding: 20px 0px 20px 20px;
  }
  .addCat-box {
    position: relative;
    width: 883px;
    margin: 0 auto;
    height: 340px;
    max-height: 90vh;
    margin-top: calc(100vh - 70vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    padding: 20px 0px 20px 20px;
  }
  .addSize-box {
    position: relative;
    width: 883px;
    margin: 0 auto;
    height: 350px;
    max-height: 80vh;
    margin-top: calc(100vh - 70vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    padding: 20px 0px 20px 20px;
  }
  .addProduct-box {
    position: relative;
    width: 883px;
    margin: 0 auto;
    height: 440px;
    max-height: 90vh;
    margin-top: calc(100vh - 76vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    padding: 20px 0px 20px 20px;
  }
}
