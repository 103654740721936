.cl-content-chalan-box {
  height: auto;
  width: 60px;
  border-radius: 5px;
  border: 1px solid black;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.checkbox-comp {
  margin-left: 5px;
}

/* ClContent-Top */
.cl-content-scroll {
  overflow-y: auto;
  height: calc(100vh - 114px);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.cl-content-scroll::-webkit-scrollbar {
  display: none;
}
.cl-content-card {
  display: flex;
  margin-top: 0.5rem;
  height: auto;
  width: 100%;
  background-color: #ffffff;
  align-items: initial;
  margin-bottom: 0.5rem;
  padding-left: 20px;
  padding-right: 20px;
}
.cl-content-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.cl-content-details {
  display: flex;
  flex-direction: row;
}
.cl-content-details-left {
}
.cl-content-details-left-side {
  display: flex;
  align-items: center;
}
.cl-content-details-left-name-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #84b7c3;
  border-radius: 8px;
  width: 58px;
  height: 58px;
}
.cl-content-details-left-name-logo-text {
  font-size: 30px;
  font-weight: 600;
}
.cl-content-details-left-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #167a93;
  margin-left: 20px;
}
.cl-content-details-left-side-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  width: 130px;
}
.cl-content-details-left-side-dot {
  margin-right: 10px;
}
.cl-content-details-left-side-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  width: 250px;
}
.cl-content-details-hr {
  height: auto;
  border: 1px solid #000000;
}
.cl-content-details-right {
}
.cl-content-details-right-doc {
  display: flex;
  margin-left: 10px;
}
.cl-content-details-right-docWname {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cl-content-details-right-doc-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 120px;
  border: 1px solid #b7b7b7;
  border-radius: 5px;
  margin-left: 20px;
}
.cl-content-details-right-doc-card-img {
  width: 30px;
  height: 30px;
}
.cl-content-details-right-btn {
  float: right;
  margin-top: 20px;
  margin-bottom: 10px;
}
.cl-content-top-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
}
.cl-content-top-left-title {
  display: flex;
  flex: 0.5;
  font-weight: 500;
  font-size: 22px;
  text-transform: uppercase;
  color: rgba(22, 122, 147, 1);
}
.cl-content-top-phone {
  color: #808080;
  text-align: center;
}
.cl-content-top-left-desc {
  font-weight: 400;
  font-size: 15px;
}
.cl-content-top-left-dropdown {
  margin-top: 20px;
  color: #3e4958;
}
.cl-content-top-right {
  display: flex;
  flex-direction: row;
}
.cl-content-top-right-btn {
  margin-top: 10px;
  align-self: flex-end;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 12px;
  padding: 10px 9px 10px 9px;
  transition: box-shadow 0.33s ease-in-out;
}
.cl-content-top-right-btn:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.cl-content-top-right-btn-icon {
  height: 14px;
  width: 10px;
}

/* Cl-Content-Middle */
.cl-content-middle {
  margin-top: 2rem;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.cl-content-middle-main {
  overflow: hidden;
}
.cl-content-middle-title-tr {
  background: rgba(247, 247, 247, 1);
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 600;
  border-bottom: white 2px solid;
  font-size: 13px;
  min-width: 8rem;
}
.cl-content-middle-name,
.cl-content-middle-mobile,
.cl-content-middle-active,
.cl-content-middle-city,
.cl-content-middle-refName,
.cl-content-middle-refDamage {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;
  font-size: 13px;
  min-width: 10rem;
}
.cl-content-middle-email,
.cl-content-middle-whats,
.cl-content-middle-date,
.cl-content-middle-address,
.cl-content-middle-refAddress,
.cl-content-middle-action {
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 500;
  font-size: 13px;
  min-width: 10rem;
}
.cl-content-middle-name-data,
.cl-content-middle-mobile-data,
.cl-content-middle-active-data,
.cl-content-middle-city-data,
.cl-content-middle-refName-data,
.cl-content-middle-refDamage-data {
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.cl-content-middle-email-data,
.cl-content-middle-whats-data,
.cl-content-middle-date-data,
.cl-content-middle-address-data,
.cl-content-middle-refAddress-data,
.cl-content-middle-action-data {
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 15px;
  min-width: 8rem;
}
.cl-content-middle-date-data {
  min-width: 8rem;
}
.cl-content-middle-action-data-div-delete {
  padding-left: 10px;
}
.customer-name {
  cursor: pointer;
}
.customer-name:hover {
  text-decoration: underline;
}
.cl-content-middle-completed {
  text-align: center;
  background: rgba(3, 163, 0, 0.32);
  border-radius: 5px;
  color: #037d00;
  font-weight: 500;
  font-size: 12px;
  padding: 2px;
  width: 100px;
}
.cl-content-middle-onGoing {
  text-align: center;
  background: rgba(255, 184, 0, 0.32);
  border-radius: 5px;
  color: rgba(255, 122, 0, 1);
  font-weight: 500;
  font-size: 12px;
  padding: 2px;
  width: 100px;
}

/* Cl-Accordian */
.cl-content-middle-table {
  overflow-x: scroll;
}
.cl-content-middle-main {
  margin-left: 15px;
  margin-right: 15px;
}
.cl-accordion-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 10px;
  margin-top: -2rem;
}
.cl-content-accordion-right-btn {
  margin-top: 10px;
  background: #167a93;
  border: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: box-shadow 0.33s ease-in-out;
}
.cl-content-accordion-right-btn:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.cl-content-accordion-right-btn-icon {
  padding-right: 10px;
}
.cl-accordion-btn-two {
  margin-left: 10px;
}
.cl-content-accordion-right-btn-del {
  margin-top: 10px;
  background: #be2c2c;
  border: #be2c2c;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 12px;
  padding: 10px 8px 10px 5px;
}
.cl-content-accordion-right-btn-del:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px rgba(155, 10, 10, 0.22);
}
.cl-content-accordion-right-btn-icon-del {
  height: 20px;
  width: 20px;
  padding: 5px;
}
.cl-content-accordion-right-btn-active {
  margin-top: 10px;
  background: #037d00;
  border: #037d00;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 12px;
  padding: 10px 25px 10px 25px;
  transition: box-shadow 0.33s ease-in-out;
}
.cl-content-accordion-right-btn-inActive {
  margin-top: 10px;
  background: lightgray;
  border: #037d00;
  border-radius: 5px;
  color: black;
  font-weight: 500;
  font-size: 12px;
  padding: 10px 25px 10px 25px;
  transition: box-shadow 0.33s ease-in-out;
}
.cl-accordion-item {
  border: 1px solid #c4c5d0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  margin-top: 20px;
}
.cl-accordion-item:hover {
  border: 1px solid #167a93;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.accordion-remian-pay {
  margin-left: 10px;
}
.accordion-title-txt {
  display: flex;
  font-weight: 400;
  font-size: 20px;
  margin-left: 10px;
  text-align: center;
}
.accordion-title-txt-desc {
  color: #04af70;
}
.accordion-remian-pay-price {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
}
.accordion-remian-pay-txt {
  color: #167a93;
  font-weight: 500;
  font-size: 16px;
}
.cl-down-arrow {
  height: 40px;
  width: 40px;
  padding-left: 10px;
}
.cl-right-arrow {
  height: 40px;
  width: 40px;
  padding-left: 10px;
}
.accordion-content {
  margin-top: 10px;
}

/* Order Summery Content */

.cl-summary-title {
  display: flex;
  flex: 0.5;
  font-weight: 500;
  font-size: 22px;
  color: rgba(22, 122, 147, 1);
}
.cl-summary-hr {
  margin-top: 10px;
  border: 1px solid lightgray;
  margin-right: 20px;
}
.cl-summary-dropdown {
  color: #3e4958;
  font-weight: 400;
  font-size: 16px;
}
.cl-summary-middle {
  margin-top: 2rem;
}
.cl-summary-middle-main {
  border-radius: 15px;
  overflow: hidden;
  margin-right: 10px;
}
.cl-summary-middle-no {
  min-width: 1rem;
  margin-right: 10px;
  padding: 7px 10px;
  font-weight: 600;
  font-size: 13px;
}
.cl-summary-middle-no-data {
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
}
.cl-summary-middle-measure-data,
.cl-summary-middle-extra-data,
.cl-summary-middle-rent1-data,
.cl-summary-middle-rent3-data {
  margin-right: 10px;
  font-size: 15px;
  padding: 7px 10px;
  min-width: 8rem;
}
.cl-summary-middle-product-data,
.cl-summary-middle-for-data,
.cl-summary-middle-quantity-data,
.cl-summary-middle-rent2-data,
.cl-summary-middle-total-data {
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 15px;
  min-width: 8rem;
}
.cl-summary-btn-div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.cl-summary-btn {
  background: #167a93;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
}
.cl-accordion-table-title {
  color: #167a93;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-left: 20px;
}

.cl-order-title-under {
  width: 178px;
  border: lightgray 1px solid;
}

.ri-accordion-item {
  border: 1px solid #c4c5d0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ri-accordion-item:hover {
  border: 1px solid #167a93;
}

.ri-accordion-title-txt {
  font-weight: 400;
  font-size: 20px;
  margin-left: 10px;
  text-align: center;
  font-weight: bold;
}

.cl-card-button-main {
  display: flex;
  margin-top: 20px;
  float: right;
}

.div-square-check {
  display: flex;
  align-items: center;
}

@media (max-width: 1500px) {
  .cl-content-card {
    overflow: scroll;
  }
}

@media (max-width: 1200px) {
  .cl-content-card {
    overflow: scroll;
  }
  .accordion-content {
    width: auto !important;
  }
  .cl-content-details-right-doc-card {
    width: 230px;
    height: 120px;
  }
}

@media (max-width: 930px) {
  .cl-card-button-main {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 641px) {
  .cl-content-accordion-right-btn {
    font-size: 10px;
  }
}
@media (max-width: 540px) {
  .cl-accordion-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-top: 1rem;
  }
}
@media (max-width: 450px) {
  .cl-content-accordion-right-btn {
    font-size: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
@media (max-width: 390px) {
  .cl-content-accordion-right-btn {
    font-size: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
