/* Place Order Content Top */
.po-inputText-input-wrapper {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  margin-top: 10px;
}
.po-inputText-input-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 300px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}
.po-add-inputText-input-container {
  flex-grow: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative;
  width: 300px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}
.po-inputText-label {
  font-weight: 500;
  font-size: 12px;
}
.po-content-top-left-txt {
  color: #167a93;
  font-weight: 500;
  font-size: 15px;
}
.po-content-top-hr {
  margin-top: 20px;
  border: 1px solid lightgray;
}
.po-content-top-second-txt {
  margin-top: 20px;
  color: #167a93;
  font-weight: 500;
  font-size: 15px;
}

/* Place Order Accordion */
.po-accordion-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.po-accordion {
  display: flex;
  padding-left: 30px;
  width: 100px;
}
.po-accordion-checkbox {
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  border: 1px solid #938f8f;
  border-radius: 5px;
}
.po-accordion-checkbox-label {
  font-weight: 500;
  font-size: 16px;
  color: #938f8f;
  margin-bottom: 0;
  padding-left: 10px;
}
.po-accordion-main-input {
  display: flex;
  flex-direction: row;
  margin-left: 60px;
}
.po-accordion-input {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.po-accordion-input-label {
  color: #938f8f;
  font-weight: 500;
  font-size: 15px;
}
.po-accordion-date-label {
  margin-top: 10px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
}
.po-accordion-second {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}
.po-accordion-date {
  display: flex;
  flex-direction: column;
}
.po-accordion-date-input {
  border-radius: 5px;
  height: 35px;
  font-size: 18px;
  border: 1px solid gray;
}
.po-accordion-second-input {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.po-accordion-input-input {
  border-radius: 5px;
  height: 35px;
  font-size: 18px;
  border: 1px solid gray;
}
.po-second-bottam-btn {
  display: flex;
  margin-left: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.po-second-bottam-btn-sub {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #167a93;
  border: #167a93;
  border-radius: 10px;
  padding: 6px 20px 6px 20px;
  transition: box-shadow 0.33s ease-in-out;
}
.po-second-bottam-btn-sub:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.po-second-bottam-btn-can {
  margin-left: 10px;
  padding: 6px 20px 6px 20px;
  background: #ffffff;
  color: black;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  border: 1px #000000 solid;
}
.po-second-bottam-btn-can:focus {
  outline: none;
}
.po-accordion-date-select-date {
  border-radius: 5px;
  height: 30px;
  border: 1px solid gray;
}
.select-date-calender {
  font-size: 12px;
}
